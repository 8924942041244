import { scrollToElement } from "@/misc/common";
import React from "react";

// First we need to add a type to let us extend the incoming component.
export type WithPaymentScrollProps = {
  completePaymentPaymentRef: (node: HTMLElement | null) => void;
  iOSSafari: boolean;
};
// Mark the function as a generic using P (or whatever variable you want)
export const withPaymentScroll = <P extends WithPaymentScrollProps>(
  Component: React.ComponentType<P>
) => {
  return class extends React.Component {
    constructor(props: any) {
      super(props);
    }

    completePaymentPaymentRef(node: HTMLElement | null) {
      if (node !== null) {
        scrollToElement({
          element: node,
          preventAutoscroll: false,
          scrollAfterStep: 0,
        });
      }
    }

    render() {
      const ua = window.navigator.userAgent;
      const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      const webkit = !!ua.match(/WebKit/i);
      const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

      return (
        // @ts-ignore
        <Component
          iOSSafari={iOSSafari}
          completePaymentPaymentRef={this.completePaymentPaymentRef}
          {...this.props}
        />
      );
    }
  };
};
