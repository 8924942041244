import React, { useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { getAccount } from "@/reducers/stripeSlice";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

const StripeCheckout: React.FC<{ clientSecret: string }> = ({ clientSecret }) => {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((s) => s.company.data?.Id);
  const accountId = useAppSelector((s) => s.stripe.entity?.accountId);

  useEffect(() => {
    if (companyId) {
      dispatch(getAccount(companyId));
    }
  }, [companyId]);

  useEffect(() => {
    if (accountId && !stripePromise) {
      const _stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
        stripeAccount: accountId,
      });

      setStripePromise(_stripePromise);
    }
  }, [accountId, stripePromise]);

  return clientSecret ? (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  ) : null;
}


export default StripeCheckout