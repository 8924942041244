import { ArticleToCreate, CountryCode, PaysonV1CreateCheckout, PaysonV2CreateCheckout, QvicklyCreateCheckout, StripeCreateCheckout } from '@/types';
import { AxiosRequestConfig } from 'axios';

interface CreateCheckout {
    Id: number;
    PaymentOrigin?: number;
    CompanyId: string;
    CheckoutUrl: string;
    ConfirmationUrl: string;
    PaymentProviderId?: number;
    CountryCode: CountryCode;
    InternalRefenceId?: string;
    Articles: ArticleToCreate[]
}

interface PaymentInterface {
    createCheckout(checkout: CreateCheckout): AxiosRequestConfig
}

export class PaysonV1Payment implements PaymentInterface {
    private url = '/payment/payson/v1/checkout';

    createCheckout(checkout: CreateCheckout): AxiosRequestConfig {
        
        let payloadData: PaysonV1CreateCheckout = {
            Articles: checkout.Articles,
            BookingId: checkout.Id,
            PaymentOrigin: String(checkout.PaymentOrigin),
            CompanyId: checkout.CompanyId,
            CheckoutUrl: {
                CheckoutUrl: checkout.CheckoutUrl
            },
            ConfirmationUrl: {
                ConfirmationUrl: checkout.ConfirmationUrl
            },
            Language: 'en',
        };

        return {
            url: this.url,
            method: 'POST',
            data: payloadData,
        }
    }
}

class PaysonV2Payment implements PaymentInterface {
    private url = '/payment/payson/v2/checkout';

    createCheckout(checkout: CreateCheckout): AxiosRequestConfig {
        let payloadData: PaysonV2CreateCheckout = {
            Articles: checkout.Articles,
            InternalReferenceId: String(checkout.Id),
            PaymentOrigin: String(checkout.PaymentOrigin),
            CompanyId: checkout.CompanyId,
            CheckoutUrl: {
                CheckoutUrl: checkout.CheckoutUrl
            },
            ConfirmationUrl: {
                ConfirmationUrl: checkout.ConfirmationUrl
            },
            CountryCode: checkout.CountryCode || 'se',
            Language: 'en',
        };

        return {
            url: this.url,
            method: 'POST',
            data: payloadData,
        }
    }
}

class QvicklyPayment implements PaymentInterface {
    private url = '/payment/billmate/v1/checkout';

    createCheckout(checkout: CreateCheckout): AxiosRequestConfig {
        
        let payloadData: QvicklyCreateCheckout = {
            Articles: checkout.Articles,
            PaymentOrigin: String(checkout.PaymentOrigin),
            CompanyId: checkout.CompanyId,
            CheckoutUrl: {
                CheckoutUrl: checkout.CheckoutUrl
            },
            ConfirmationUrl: {
                ConfirmationUrl: checkout.ConfirmationUrl
            },
            InternalReferenceId: String(checkout.Id),
            Language: 'en',
        };

        return {
            url: this.url,
            method: 'POST',
            data: payloadData,
        }
    }
}

class StripePayment implements PaymentInterface {
    private url = '/payment/stripe/v1/checkout';
    private uiMode = 'embedded';

    createCheckout(checkout: CreateCheckout): AxiosRequestConfig {
        let payloadData: StripeCreateCheckout = {
            Articles: checkout.Articles,
            InternalReferenceId: String(checkout.Id),
            CompanyId: checkout.CompanyId,
            ConfirmationUrl: checkout.ConfirmationUrl,
            CountryCode: checkout.CountryCode || 'se',
            UiMode: this.uiMode
        };

        return {
            url: this.url,
            method: 'POST',
            data: payloadData,
        }
    }
}

class Payment implements PaymentInterface {

    createCheckout(checkout: CreateCheckout): AxiosRequestConfig {
        if(checkout.PaymentProviderId === 1) {
            return new PaysonV1Payment().createCheckout(checkout);
        } else if (checkout.PaymentProviderId === 2) {
            return new PaysonV2Payment().createCheckout(checkout);
        } else if (checkout.PaymentProviderId === 3) {
            return new QvicklyPayment().createCheckout(checkout);
        } else if (checkout.PaymentProviderId === 5) {
            return new StripePayment().createCheckout(checkout);
        } else {
            throw new Error('Invalid PaymentProviderId');
        }
    }
}

export default Payment;